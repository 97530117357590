import { Pipe, PipeTransform } from '@angular/core'
import { IReportTimeConfigPoint, ReportTimeConfigPointPosition, TemplateTimeType } from '@shared/models'
import { TimePeriodService } from '@shared/services'


@Pipe({
    name: 'timePeriodSimple',
})
export class TimePeriodSimplePipe implements PipeTransform {

    constructor(private timePeriodService: TimePeriodService) { }

    transform(timeConfigPoint: IReportTimeConfigPoint, position: ReportTimeConfigPointPosition, timeType?: TemplateTimeType | null): string {
        if (!timeConfigPoint || !position) {
            return ''
        }
        return this.timePeriodService.getSimpleLabel(timeConfigPoint, position, timeType)

    }

}
