import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { SafeHtmlPipe } from '@shared/pipes'


@Component({
    selector: 'app-notice',
    templateUrl: './notice.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        SafeHtmlPipe,
    ],
})

export class NoticeComponent {

    @Input() noticeContent: string


}
