import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { AppBaseComponent } from '@common/components'
import { UploadMetadataType, UploadMetadataTypeLabel } from '@shared/models'
import { ApiService, NotifyService } from '@shared/services'
import { catchError, finalize, takeUntil, throwError } from 'rxjs'


@Component({
    selector: 'app-asda-upload-config-file-dialog',
    templateUrl: './asda-upload-config-file-dialog.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatProgressBarModule,
    ],
})
export class AsdaUploadConfigFileDialogComponent extends AppBaseComponent {

    isProcessing: boolean
    uploadedFile: File | null

    readonly formGroup = new FormGroup({
        fileName: new FormControl<string | null>(null, Validators.required),
        type: new FormControl<UploadMetadataType>(UploadMetadataType.COLUMNS, Validators.required),
    })
    readonly acceptedFileTypes = ['.xlsx']
    readonly UploadMetadataTypeLabel = UploadMetadataTypeLabel

    constructor(
        private apiService: ApiService,
        private notifyService: NotifyService,
        private matDialogRef: MatDialogRef<AsdaUploadConfigFileDialogComponent>) {
        super()
    }

    onSubmit(): void {
        const { type } = this.formGroup.value
        this.uploadFile(type!, this.uploadedFile!)
    }

    onDragOver(event: Event): void {
        event.preventDefault()
    }

    onDrop(event: DragEvent): void {
        event.preventDefault()
        const file = event.dataTransfer?.files?.item(0) ?? null
        this.saveFile(file)
    }

    onFileChange(event: Event): void {
        const target = event.target as HTMLInputElement
        const file = target.files?.item(0) ?? null
        this.saveFile(file)
    }

    onRemoveFile(): void {
        this.uploadedFile = null
        this.formGroup.get('fileName')?.setValue(null)
    }

    protected saveFile(file: File | null): void {
        if (!file) {
            return
        }
        else if (!this.isValidFileType(file)) {
            return
        }
        this.uploadedFile = file
        this.formGroup.get('fileName')?.setValue(file.name)
    }

    protected isValidFileType(file: File): boolean {
        if (!this.acceptedFileTypes.some((type) => file.name?.toLowerCase().endsWith(type))) {
            this.notifyService.warning('Invalid file type. Please upload an Excel file.')
            return false
        }
        return true
    }

    protected uploadFile(type: UploadMetadataType, file: File): void {
        this.isProcessing = true
        this.apiService.uploadMetadata(type, file)
            .pipe(
                catchError((error) => {
                    this.notifyService.error('Failed to upload config file. Please try again.')
                    return throwError(() => error)
                }),
                takeUntil(this.destroyed$),
                finalize(() => this.isProcessing = false),
            )
            .subscribe(() => {
                this.notifyService.info('Config file uploaded successfully.')
                this.matDialogRef.close()

            })
    }

}
