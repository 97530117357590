<div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center p-4">
        <h4>Select Date</h4>
        <button mat-icon-button
                aria-label="close dialog"
                mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="pt-0">
        <form class="d-flex flex-column gap-2"
              [formGroup]="formGroup"
              (submit)="onSubmit()">
            <mat-tab-group class="mb-3"
                           (selectedTabChange)="onSelectTypeChange($event)">
                <mat-tab [label]="DateSelectType.DATE">
                    <ng-template mat-tab-label>
                        <div class="fw-medium">Date</div>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="DateSelectType.ASDA_WEEK">
                    <ng-template mat-tab-label>
                        <div class="fw-medium">Asda Week</div>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="DateSelectType.WALMART_WEEK">
                    <ng-template mat-tab-label>
                        <div class="fw-medium">Walmart Week</div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <div class="row">
                <div *ngIf="type === DateSelectType.DATE"
                     class="col-12">
                    <mat-form-field class="w-100"
                                    appearance="outline">
                        <mat-label>Calendar Date</mat-label>
                        <input matInput
                               [formControlName]="'date'"
                               readonly
                               [matDatepicker]="picker"
                               (click)="picker.open()"
                               placeholder="Choose a date">
                        <mat-datepicker-toggle matSuffix
                                               [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker
                                        cdkFocusInitial></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="type === DateSelectType.ASDA_WEEK"
                     class="col-6">
                    <mat-form-field class="w-100"
                                    appearance="outline">
                        <mat-label>Enter Asda Week</mat-label>
                        <input matInput
                               type="number"
                               min="0"
                               [formControlName]="'asdaWeek'"
                               placeholder="e.g. 33">
                    </mat-form-field>
                </div>
                <div *ngIf="type === DateSelectType.WALMART_WEEK">
                    <mat-form-field class="w-100"
                                    appearance="outline">
                        <mat-label>Enter Walmart Week</mat-label>
                        <input matInput
                               type="number"
                               min="0"
                               [formControlName]="'walmartWeek'"
                               placeholder="e.g. 12048">
                    </mat-form-field>
                </div>
                <div *ngIf="type === DateSelectType.ASDA_WEEK"
                     class="col-6">
                    <mat-form-field class="w-100"
                                    appearance="outline">

                        <mat-label>Enter Year</mat-label>
                        <input matInput
                               type="number"
                               min="0"
                               [formControlName]="'year'"
                               placeholder="e.g. 2024">
                    </mat-form-field>
                </div>
            </div>
            <button mat-flat-button
                    class="mt-1 text-white"
                    type="submit"
                    [disabled]="!formGroup.valid"
                    color="primary"
                    (click)="onSubmit()">
                Search
            </button>
        </form>

        <mat-progress-bar *ngIf="isLoading"
                          mode="indeterminate"></mat-progress-bar>

        <div class="mt-4"
             style="height: 425px; overflow: auto;">
            <table mat-table
                   matSort
                   [dataSource]="dataSource ?? []">
                <ng-container matColumnDef="calendarDate"
                              sticky>
                    <th mat-header-cell
                        *matHeaderCellDef>
                        <div class="mat-header-wrapper">
                            <div>Date</div>
                        </div>
                    </th>
                    <td mat-cell
                        *matCellDef="let element"
                        class="cursor-pointer"
                        [matTooltip]="'Click to copy ' + element.calendarDate"
                        [matTooltipShowDelay]="200"
                        (click)="onCopyValue(element.calendarDate)">
                        {{ element.calendarDate | date: DATE_FORMAT }}
                    </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="asdaWeek">
                    <th mat-header-cell
                        *matHeaderCellDef>Asda Week</th>
                    <td mat-cell
                        *matCellDef="let element"
                        class="cursor-pointer"
                        [matTooltipShowDelay]="200"
                        [matTooltip]="'Click to copy ' + element.asdaWeek"
                        (click)="onCopyValue(element.asdaWeek)">

                        {{ element.asdaWeek }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="walmartWeek">
                    <th mat-header-cell
                        *matHeaderCellDef>Walmart Week</th>
                    <td mat-cell
                        *matCellDef="let element"
                        class="cursor-pointer"
                        [matTooltipShowDelay]="200"
                        [matTooltip]="'Click to copy ' + element.wmYrWk"
                        (click)="onCopyValue(element.wmYrWk)">

                        {{ element.wmYrWk }}
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="DisplayedColumns sticky:true "></tr>
                <tr mat-row
                    *matRowDef="let row; columns: DisplayedColumns"></tr>
            </table>
        </div>
    </mat-dialog-content>
</div>