export interface ICalendarData {
    calendarDate: string
    wmYrWk: number
    asdaWeek: number
}

export interface ICalendarRequest {
    asdaWeek?: number
    year?: number
    wmYrWk?: number
    date?: string
}
export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export enum DateSelectType {
    DATE = 'DATE',
    ASDA_WEEK = 'ASDA WEEK',
    WALMART_WEEK = 'WALMART WEEK',
}
