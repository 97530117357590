import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'
import { BrowserUtils } from '@azure/msal-browser'
import { environment } from '@environments/environment'
import { CurrentUserGuard } from '@shared/guards'


const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'home',
        redirectTo: '',
    },
    {
        path: 'new-report',
        loadChildren: () => import('./modules/new-report/new-report.module').then(m => m.NewReportModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'report-status',
        loadChildren: () => import('./modules/report-status/report-status.module').then(m => m.ReportStatusModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'my-reports',
        loadChildren: () => import('./modules/my-reports/my-reports.module').then(m => m.MyReportsModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'user-management',
        loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'metadata-dashboard',
        loadChildren: () => import('./modules/metadata-dashboard/metadata-dashboard.module').then(m => m.MetadataDashboardModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'status-dashboard',
        loadChildren: () => import('./modules/status-dashboard/status-dashboard.module').then(m => m.StatusDashboardModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard, CurrentUserGuard.canActivate] : [])],
    },
    {
        path: 'error',
        loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
        canActivate: [...(environment.useAuthorization ? [MsalGuard] : [])],
    },
    {
        path: '**',
        redirectTo: '',
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes or popups
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
                ? 'enabledNonBlocking'
                : 'disabled', // Set to enabledBlocking to use Angular Universal
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
