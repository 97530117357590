import { CommonModule } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSort, MatSortModule } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { AppBaseComponent } from '@common/components'
import { IRunSqlResponse } from '@shared/models'
import { ApiService, NotifyService } from '@shared/services'
import * as dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { isString } from 'lodash-es'
import { catchError, finalize, takeUntil, throwError } from 'rxjs'

import { AsdaMessagePopupComponent } from './../asda-message-popup/asda-message-popup.component'


@Component({
    selector: 'app-asda-direct-sql-dialog',
    templateUrl: './asda-direct-sql-dialog.component.html',
    styleUrls: ['./asda-direct-sql-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatIconModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatTableModule,
        MatSortModule,
    ],
})
export class AsdaDirectSqlDialogComponent extends AppBaseComponent {

    @ViewChild(MatSort) sort: MatSort

    isLoading: boolean
    sqlResult: IRunSqlResponse | null
    displayedColumns: string[]
    dataSource: MatTableDataSource<Record<string, string>>
    errorMessage: string | null
    copyErrorMessagePlaceholder = 'Copy'

    formGroup: FormGroup = new FormGroup({
        query: new FormControl<string>('', Validators.required),
        asCsv: new FormControl(false),
    })

    constructor(
        private apiService: ApiService,
        private notifyService: NotifyService,
        private matDialog: MatDialog) {
        super()
    }

    onSubmit(): void {
        if (this.dataSource) {
            this.dataSource.data = []
            this.displayedColumns = []
        }
        const { query, asCsv } = this.formGroup.value

        if (query.toLowerCase().includes('limit')) {
            this.runDirectSqlQuery(query, asCsv)
        }
        else {
            const confirmDialog = this.matDialog.open(AsdaMessagePopupComponent, {
                data: {
                    title: 'Warning',
                    message: 'SQL Query doesn\'t not contain \'LIMIT\' keyword. Are you sure, you want to run this query?',
                },
            })
            confirmDialog.afterClosed().subscribe((result) => {
                if (result) {
                    this.runDirectSqlQuery(query, asCsv)
                }
            })
        }
    }

    onCopyErrorMessage(errorResponse: Record<any, any> | string = '') {
        this.copyErrorMessagePlaceholder = 'Copied!'
        const textToCopy = isString(errorResponse) ? errorResponse : JSON.stringify(errorResponse, null, 2)

        navigator.clipboard.writeText(textToCopy)
            .then(() => setTimeout(() => this.copyErrorMessagePlaceholder = 'Copy', 1500))
            .catch(() => { })
    }

    protected initDataSource(response: IRunSqlResponse): void {
        this.sqlResult = response
        this.displayedColumns = this.sqlResult.headers
        const data = this.sqlResult.rows.map((row) => {
            return row.reduce((acc, value, index) => {
                acc[this.displayedColumns[index]] = value
                return acc
            }, {} as Record<string, string>)
        })
        this.dataSource = new MatTableDataSource(data)
        this.dataSource.sort = this.sort
    }

    protected runDirectSqlQuery(query: string, asCsv: boolean): void {
        this.isLoading = true
        this.sqlResult = null
        this.errorMessage = null
        this.apiService.runSqlQuery(query, asCsv)
            .pipe(
                catchError((errorResponse) => {
                    if (errorResponse instanceof HttpErrorResponse) {
                        this.errorMessage = errorResponse.error ?? errorResponse.message
                    }
                    else {
                        this.errorMessage = errorResponse
                    }
                    this.notifyService.error('Failed to run SQL query')
                    return throwError(() => errorResponse)
                }),
                takeUntil(this.destroyed$),
                finalize(() => this.isLoading = false),
            )
            .subscribe((response) => {
                if (asCsv) {
                    const currentDate = dayjs().format('DDMMYYYY_HHmm')
                    saveAs(response as Blob, `sql-query-result_${currentDate}.csv`)
                }
                else {
                    this.initDataSource(response as IRunSqlResponse)
                }
            })
    }

}
