import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSelectModule } from '@angular/material/select'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { AppBaseComponent } from '@common/components'
import { DATE_FORMAT } from '@shared/constants'
import { ApiService } from '@shared/services'
import * as dayjs from 'dayjs'
import { isNil, omitBy } from 'lodash-es'
import { delay, takeUntil } from 'rxjs'

import { API_DATE_FORMAT, DateSelectType, ICalendarData, ICalendarRequest } from '../../models'


@Component({
    selector: 'app-asda-date-select-dialog',
    templateUrl: './asda-date-select-dialog.component.html',
    styleUrls: ['./asda-date-select-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatSelectModule,
        MatTooltipModule,
        MatTableModule,
        MatProgressBarModule,
    ],
})
export class AsdaDateSelectDialogComponent extends AppBaseComponent implements OnInit, OnDestroy {

    isLoading: boolean
    dataSource?: MatTableDataSource<ICalendarData>

    formGroup: FormGroup = new FormGroup({
        type: new FormControl<DateSelectType>(DateSelectType.DATE),
        date: new FormControl(''),
        asdaWeek: new FormControl<number | null>(null),
        walmartWeek: new FormControl<number | null>(null),
        year: new FormControl<number | null>(null),
    })

    readonly DATE_FORMAT = DATE_FORMAT
    readonly DateSelectType = DateSelectType
    readonly DisplayedColumns: string[] = [
        'calendarDate',
        'asdaWeek',
        'walmartWeek',
    ]

    constructor(private apiService: ApiService) {
        super()
    }

    get type(): DateSelectType {
        return this.formGroup.controls['type'].value as DateSelectType
    }

    ngOnInit(): void {
        const currentDate = dayjs()
        this.fillCurrentDate()
        this.loadCalendarData({ date: this.getDateString(currentDate) }, true)
        this.watchFormChanges()
    }

    getDateString(date: dayjs.Dayjs): string {
        return date.format(API_DATE_FORMAT)
    }

    onSelectTypeChange(event: MatTabChangeEvent): void {
        const type = event.tab.textLabel as DateSelectType
        this.formGroup.patchValue({ type })
    }

    fillCurrentDate(): void {
        const currentDate = dayjs()
        this.formGroup.patchValue({ date: currentDate.toISOString(), year: currentDate.year() })
    }

    onSubmit(): void {
        const { type, date, asdaWeek, walmartWeek, year } = this.formGroup.value
        const request: ICalendarRequest = {
            date: type === DateSelectType.DATE ? this.getDateString(dayjs(date)) : undefined,
            asdaWeek: type === DateSelectType.ASDA_WEEK ? +asdaWeek : undefined,
            year: type === DateSelectType.ASDA_WEEK ? +year : undefined,
            wmYrWk: type === DateSelectType.WALMART_WEEK ? +walmartWeek : undefined,
        }

        this.loadCalendarData(omitBy(request, isNil))
    }

    onCopyValue(text: string) {
        navigator.clipboard.writeText(text)
            .then()
            .catch(() => { })
    }

    protected watchFormChanges(): void {
        this.formGroup.valueChanges
            .pipe(
                delay(0),
                takeUntil(this.destroyed$),
            )
            .subscribe((value) => {
                switch (value.type) {
                    case DateSelectType.DATE:
                        this.formGroup.controls['date'].setValidators([Validators.required])
                        this.formGroup.controls['asdaWeek'].setValidators([])
                        this.formGroup.controls['walmartWeek'].setValidators([])
                        this.formGroup.controls['year'].setValidators([])
                        break
                    case DateSelectType.ASDA_WEEK:
                        this.formGroup.controls['date'].setValidators([])
                        this.formGroup.controls['asdaWeek'].setValidators([Validators.required])
                        this.formGroup.controls['walmartWeek'].setValidators([])
                        this.formGroup.controls['year'].setValidators([Validators.required])
                        break
                    case DateSelectType.WALMART_WEEK:
                        this.formGroup.controls['date'].setValidators([])
                        this.formGroup.controls['asdaWeek'].setValidators([])
                        this.formGroup.controls['walmartWeek'].setValidators([Validators.required])
                        this.formGroup.controls['year'].setValidators([])
                        break
                }
                this.formGroup.controls['date'].updateValueAndValidity({ emitEvent: false })
                this.formGroup.controls['asdaWeek'].updateValueAndValidity({ emitEvent: false })
                this.formGroup.controls['walmartWeek'].updateValueAndValidity({ emitEvent: false })
                this.formGroup.controls['year'].updateValueAndValidity({ emitEvent: false })
            })
    }

    protected loadCalendarData(request: ICalendarRequest, fillForm = false) {
        this.isLoading = true
        this.apiService.fetchCalendar(request)
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((response) => {
                this.dataSource = new MatTableDataSource(response)
                this.isLoading = false
                if (fillForm) {
                    this.formGroup.patchValue({
                        asdaWeek: response[0].asdaWeek,
                        walmartWeek: response[0].wmYrWk,
                    })
                }
            })
    }

}
