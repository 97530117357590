import { Injectable } from '@angular/core'
import { DATE_FORMAT_DAYJS } from '@shared/constants'
import {
    API_DATE_FORMAT,
    ICalendarData,
    IReportTimeConfigPoint,
    MonthOfTheYearLabel,
    ReportTimeConfigPointPosition,
    TemplateTimeType,
    TimeConfigType,
    TimeConfigYearType,
} from '@shared/models'
import * as dayjs from 'dayjs'
import * as isLeapYear from 'dayjs/plugin/isLeapYear'
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import * as weekOfYear from 'dayjs/plugin/weekOfYear'
import { isNil } from 'lodash-es'
import { Observable, catchError, map, of } from 'rxjs'

import { ApiService } from './api.service'


@Injectable({
    providedIn: 'root',
})
export class TimePeriodService {

    constructor(private apiService: ApiService) {
        dayjs.extend(weekOfYear)
        dayjs.extend(isLeapYear)
        dayjs.extend(isoWeeksInYear)
    }

    getSimpleLabel(timeConfigPoint: IReportTimeConfigPoint, position: ReportTimeConfigPointPosition, timeType?: TemplateTimeType | null): string {
        if (!timeConfigPoint || !position) {
            return ''
        }

        switch (timeConfigPoint.type) {
            case TimeConfigType.TODAY: {
                return 'Today (when report is run)'
            }
            case TimeConfigType.DATE: {
                return dayjs(timeConfigPoint.date).format(DATE_FORMAT_DAYJS)
            }
            case TimeConfigType.DAYS_BEFORE: {
                return timeType === TemplateTimeType.FUTURE
                    ? `${timeConfigPoint.before ?? 0} days after report is run`
                    : `${timeConfigPoint.before ?? 0} days before report is run`
            }
            case TimeConfigType.CALENDAR_WEEKS_BEFORE: {
                return timeType === TemplateTimeType.FUTURE
                    ? `${timeConfigPoint.before ?? 0} calendar weeks after report is run`
                    : `${timeConfigPoint.before ?? 0} calendar weeks before report is run`
            }
            case TimeConfigType.ASDA_WEEKS_BEFORE: {
                return timeType === TemplateTimeType.FUTURE
                    ? `${timeConfigPoint.before ?? 0} Asda weeks after report is run`
                    : `${timeConfigPoint.before ?? 0} Asda weeks before report is run`
            }
            case TimeConfigType.MONTH: {
                return `${MonthOfTheYearLabel.get(timeConfigPoint.month!) ?? 0}`
            }
            case TimeConfigType.MONTHS_BEFORE: {
                return timeType === TemplateTimeType.FUTURE
                    ? `${timeConfigPoint.before ?? 0} months after report is run`
                    : `${timeConfigPoint.before ?? 0} months before report is run`
            }
            case TimeConfigType.WALMART_WEEK: {
                return `Walmart Week #${timeConfigPoint.walmartWeek ?? 0}`
            }
            case TimeConfigType.ASDA_WEEK: {
                const year = dayjs().year() - timeConfigPoint.yearsBefore!
                return `Asda Week #${timeConfigPoint.asdaWeek ?? 0} of ${year ?? 0}`
            }
            case TimeConfigType.WHOLE_YEAR: {
                const dateValue = timeType === TemplateTimeType.FUTURE
                    ? dayjs().add(timeConfigPoint.yearsBefore ?? 0, 'years')
                    : dayjs().subtract(timeConfigPoint.yearsBefore ?? 0, 'years')
                if (position === ReportTimeConfigPointPosition.START) {
                    if (timeConfigPoint.yearType === TimeConfigYearType.CALENDAR) {
                        return `Calendar year - ${dateValue.startOf('year').format(DATE_FORMAT_DAYJS)}`
                    }
                    else if (timeConfigPoint.yearType === TimeConfigYearType.FISCAL) {
                        return `Fiscal year - ${dateValue.startOf('year').add(1, 'month').format(DATE_FORMAT_DAYJS)}`
                    }
                }
                else if (position === ReportTimeConfigPointPosition.END) {
                    if (timeConfigPoint.yearType === TimeConfigYearType.CALENDAR) {
                        return `Calendar year - ${dateValue.endOf('year').format(DATE_FORMAT_DAYJS)}`
                    }
                    else if (timeConfigPoint.yearType === TimeConfigYearType.FISCAL) {
                        return `Fiscal year - ${dateValue.endOf('year').add(1, 'month').format(DATE_FORMAT_DAYJS)}`
                    }
                }
                const year = timeType === TemplateTimeType.FUTURE
                    ? dayjs().year() + timeConfigPoint.yearsBefore!
                    : dayjs().year() - timeConfigPoint.yearsBefore!
                if (position === ReportTimeConfigPointPosition.START) {
                    if (timeConfigPoint.yearType === TimeConfigYearType.CALENDAR) {
                        return `January 1, ${year ?? 0}`
                    }
                    else if (timeConfigPoint.yearType === TimeConfigYearType.FISCAL) {
                        return `February 1, ${year ?? 0}`
                    }
                }
                else if (position === ReportTimeConfigPointPosition.END) {
                    if (timeConfigPoint.yearType === TimeConfigYearType.CALENDAR) {
                        return `December 31, ${year ?? 0}`
                    }
                    else if (timeConfigPoint.yearType === TimeConfigYearType.FISCAL) {
                        return `January 31, ${year ?? 0}`
                    }
                }
                return ''
            }
            default: {
                return 'Unknown time period type'
            }
        }
    }

    fetchEffectiveLabel(
        timeConfigPoint: IReportTimeConfigPoint,
        position: ReportTimeConfigPointPosition,
        timeType?: TemplateTimeType | null,
    ): Observable<string> {
        const today = dayjs()

        switch (timeConfigPoint.type) {
            case TimeConfigType.TODAY: {
                return of(today.format(DATE_FORMAT_DAYJS))
            }
            case TimeConfigType.DATE: {
                if (isNil(timeConfigPoint.date)) {
                    return of('')
                }
                return of(dayjs(timeConfigPoint.date).format(DATE_FORMAT_DAYJS))
            }
            case TimeConfigType.DAYS_BEFORE: {
                if (isNil(timeConfigPoint.before)) {
                    return of('')
                }
                return timeType === TemplateTimeType.FUTURE
                    ? of(dayjs().add(timeConfigPoint.before ?? 0, 'days').format(DATE_FORMAT_DAYJS))
                    : of(dayjs().subtract(timeConfigPoint.before ?? 0, 'days').format(DATE_FORMAT_DAYJS))
            }
            case TimeConfigType.CALENDAR_WEEKS_BEFORE: {
                if (isNil(timeConfigPoint.before)) {
                    return of('')
                }
                return timeType === TemplateTimeType.FUTURE
                    ? of(this.fetchCalendarWeekAfter(timeConfigPoint.before ?? 0, position))
                    : of(this.fetchCalendarWeekBefore(timeConfigPoint.before ?? 0, position))
            }
            case TimeConfigType.ASDA_WEEKS_BEFORE: {
                if (isNil(timeConfigPoint.before)) {
                    return of('')
                }
                return timeType === TemplateTimeType.FUTURE
                    ? this.fetchAsdaWeekAfter(timeConfigPoint.before ?? 0, position)
                    : this.fetchAsdaWeekBefore(timeConfigPoint.before ?? 0, position)
            }
            case TimeConfigType.MONTH: {
                if (isNil(timeConfigPoint.month)) {
                    return of('')
                }
                if (position === ReportTimeConfigPointPosition.START) {
                    return of(`${dayjs().month(timeConfigPoint.month - 1).startOf('month').format(DATE_FORMAT_DAYJS)}`)
                }
                else if (position === ReportTimeConfigPointPosition.END) {
                    return of(`${dayjs().month(timeConfigPoint.month - 1).endOf('month').format(DATE_FORMAT_DAYJS)}`)
                }
                return of('')
            }
            case TimeConfigType.MONTHS_BEFORE: {
                if (isNil(timeConfigPoint.before)) {
                    return of('')
                }
                const dateValue = timeType === TemplateTimeType.FUTURE
                    ? today.add(timeConfigPoint.before ?? 0, 'months')
                    : today.subtract(timeConfigPoint.before ?? 0, 'months')
                if (position === ReportTimeConfigPointPosition.START) {
                    return of(`${dateValue.startOf('month').format(DATE_FORMAT_DAYJS)}`)
                }
                else if (position === ReportTimeConfigPointPosition.END) {
                    return of(`${dateValue.endOf('month').format(DATE_FORMAT_DAYJS)}`)
                }
                return of('')
            }
            case TimeConfigType.WALMART_WEEK: {
                if (isNil(timeConfigPoint.walmartWeek)) {
                    return of('')
                }
                return this.fetchWalmartWeek(+timeConfigPoint.walmartWeek, position)
            }
            case TimeConfigType.ASDA_WEEK: {
                if (isNil(timeConfigPoint.asdaWeek) || isNil(timeConfigPoint.yearsBefore)) {
                    return of('')
                }
                return timeType === TemplateTimeType.FUTURE
                    ? this.fetchAsdaWeekYearsAfter(timeConfigPoint.asdaWeek, timeConfigPoint.yearsBefore ?? 0, position)
                    : this.fetchAsdaWeekYearsBefore(timeConfigPoint.asdaWeek, timeConfigPoint.yearsBefore ?? 0, position)
            }
            case TimeConfigType.WHOLE_YEAR: {
                if (isNil(timeConfigPoint.yearType) || isNil(timeConfigPoint.yearsBefore)) {
                    return of('')
                }
                const dateValue = timeType === TemplateTimeType.FUTURE
                    ? today.add(timeConfigPoint.yearsBefore ?? 0, 'years')
                    : today.subtract(timeConfigPoint.yearsBefore ?? 0, 'years')

                if (position === ReportTimeConfigPointPosition.START) {
                    if (timeConfigPoint.yearType === TimeConfigYearType.CALENDAR) {
                        return of(`${dateValue.startOf('year').format(DATE_FORMAT_DAYJS)}`)
                    }
                    else if (timeConfigPoint.yearType === TimeConfigYearType.FISCAL) {
                        return of(`${dateValue.startOf('year').add(1, 'month').format(DATE_FORMAT_DAYJS)}`)
                    }
                }
                else if (position === ReportTimeConfigPointPosition.END) {
                    if (timeConfigPoint.yearType === TimeConfigYearType.CALENDAR) {
                        return of(`${dateValue.endOf('year').format(DATE_FORMAT_DAYJS)}`)
                    }
                    else if (timeConfigPoint.yearType === TimeConfigYearType.FISCAL) {
                        return of(`${dateValue.endOf('year').add(1, 'month').format(DATE_FORMAT_DAYJS)}`)
                    }
                }
                return of('')
            }
            default: {
                return of('Unknown time period type')
            }
        }
    }

    protected isAsdaWeekYearExtended(year: number): Observable<boolean> {
        const LAST_ASDA_WEEK = 53
        return this.apiService.fetchCalendar({
            asdaWeek: LAST_ASDA_WEEK,
            year: year,
        })
            .pipe(
                catchError(() => of([])),
                map((response) => !!response.length),
            )
    }

    protected fetchCalendarWeekBefore(before: number, position: ReportTimeConfigPointPosition): string {
        if (position === ReportTimeConfigPointPosition.START) {
            return dayjs().subtract(before, 'weeks').startOf('week').format(DATE_FORMAT_DAYJS)
        }
        else if (position === ReportTimeConfigPointPosition.END) {
            return dayjs().subtract(before, 'weeks').endOf('week').format(DATE_FORMAT_DAYJS)
        }
        return ''
    }

    protected fetchCalendarWeekAfter(after: number, position: ReportTimeConfigPointPosition): string {
        if (position === ReportTimeConfigPointPosition.START) {
            return dayjs().add(after, 'weeks').startOf('week').format(DATE_FORMAT_DAYJS)
        }
        else if (position === ReportTimeConfigPointPosition.END) {
            return dayjs().add(after, 'weeks').endOf('week').format(DATE_FORMAT_DAYJS)
        }
        return ''
    }


    protected fetchAsdaWeekBefore(before: number, position: ReportTimeConfigPointPosition): Observable<string> {
        return this.apiService
            .fetchCalendar({
                date: dayjs().subtract(before, 'weeks').format(API_DATE_FORMAT),
            })
            .pipe(
                catchError(() => of([])),
                map((response) => this.getMappedCalendarDate(response, position)),
            )
    }

    protected fetchAsdaWeekAfter(after: number, position: ReportTimeConfigPointPosition): Observable<string> {
        return this.apiService
            .fetchCalendar({
                date: dayjs().add(after, 'weeks').format(API_DATE_FORMAT),
            })
            .pipe(
                catchError(() => of([])),
                map((response) => this.getMappedCalendarDate(response, position)),
            )
    }

    protected fetchWalmartWeek(walmartWeek: number, position: ReportTimeConfigPointPosition): Observable<string> {
        return this.apiService.fetchCalendar({
            wmYrWk: walmartWeek,
        })
            .pipe(
                catchError(() => of([])),
                map((response) => this.getMappedCalendarDate(response, position)),
            )
    }

    protected fetchAsdaWeekYearsBefore(asdaWeek: number, yearsBefore: number, position: ReportTimeConfigPointPosition): Observable<string> {
        const year = dayjs().subtract(yearsBefore ?? 0, 'years').year()

        return this.apiService.fetchCalendar({
            asdaWeek: asdaWeek,
            year: year,
        })
            .pipe(
                catchError(() => of([])),
                map((response) => this.getMappedCalendarDate(response, position)),
            )
    }

    protected fetchAsdaWeekYearsAfter(asdaWeek: number, yearsBefore: number, position: ReportTimeConfigPointPosition): Observable<string> {
        const year = dayjs().add(yearsBefore ?? 0, 'years').year()

        return this.apiService.fetchCalendar({
            asdaWeek: asdaWeek,
            year: year,
        })
            .pipe(
                catchError(() => of([])),
                map((response) => this.getMappedCalendarDate(response, position)),
            )
    }

    protected getMappedCalendarDate(response: ICalendarData[], position: ReportTimeConfigPointPosition): string {
        if (!response.length) {
            return 'Invalid Week'
        }
        if (position === ReportTimeConfigPointPosition.START) {
            return `${dayjs(response[0].calendarDate).format(DATE_FORMAT_DAYJS)}`
        }
        else if (position === ReportTimeConfigPointPosition.END) {
            return `${dayjs(response[response.length - 1].calendarDate).format(DATE_FORMAT_DAYJS)}`
        }
        return ''
    }

}
