import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { X_API_KEY } from '@shared/constants'
import { Observable } from 'rxjs'


@Injectable()
export class HeaderApiKeyInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const modifiedRequest = request.clone({
            setHeaders: {
                [X_API_KEY]: environment.xApiKey,
            },
        })
        return next.handle(modifiedRequest)
    }

}
