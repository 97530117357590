import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { APIM_KEY } from '@shared/constants'
import { Observable } from 'rxjs'


@Injectable()
export class HeaderApimKeyInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const headers: Record<string, string> =
            environment.apimKey
                ? {
                    [APIM_KEY]: environment.apimKey,
                }
                : {}
        const modifiedRequest = request.clone({
            setHeaders: {
                ...headers,
            },
        })
        return next.handle(modifiedRequest)
    }

}
