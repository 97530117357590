import {
    FolderOutputStyle,
    NodeFormatType,
    ReportFrequencyDayOfTheWeek,
    ReportFrequencyType,
    ReportOutputStyle,
    ReportRunStatus,
    ReportRunType,
    TemplateStageSectionNodeFilter,
    TemplateStageType,
    TimeConfigType,
    TimeConfigYearType,
    UploadMetadataType,
    UserActivity,
} from './api.models'


export const TemplateStageSectionNodeFilterLabel = new Map<TemplateStageSectionNodeFilter, string>([
    [TemplateStageSectionNodeFilter.ONE_OF, 'Is one of'],
    [TemplateStageSectionNodeFilter.NOT_ONE_OF, 'Is not one of'],
    [TemplateStageSectionNodeFilter.BETWEEN, 'Is Between'],
    [TemplateStageSectionNodeFilter.NOT_BETWEEN, 'Is not between'],
    [TemplateStageSectionNodeFilter.LIKE, 'Is like'],
    [TemplateStageSectionNodeFilter.NOT_LIKE, 'Is not like'],
    [TemplateStageSectionNodeFilter.EQUAL, 'Is equal'],
    [TemplateStageSectionNodeFilter.NOT_EQUAL, 'Is not equal'],
    [TemplateStageSectionNodeFilter.GREATER_THAN, 'Is greater than'],
    [TemplateStageSectionNodeFilter.GREATER_OR_EQUAL_THAN, 'Is greater or equal than'],
    [TemplateStageSectionNodeFilter.LESS_THAN, 'Is less than'],
    [TemplateStageSectionNodeFilter.LESS_OR_EQUAL_THAN, 'Is less or equal than'],
])

export const ReportRunStatusLabel = new Map<ReportRunStatus, string>([
    [ReportRunStatus.QUEUED, 'Queued'],
    [ReportRunStatus.IN_PROGRESS_SQL, 'Query in progress'],
    [ReportRunStatus.WAITING_FORMAT, 'Wait for formatting'],
    [ReportRunStatus.IN_PROGRESS_FORMAT, 'Formatting in progress'],
    [ReportRunStatus.COMPLETED, 'Completed'],
])

export const ReportFrequencyTypeLabel = new Map<ReportFrequencyType, string>([
    [ReportFrequencyType.NONE, 'Not scheduled'],
    [ReportFrequencyType.ONCE, 'Once'],
    [ReportFrequencyType.DAILY, 'Daily'],
    [ReportFrequencyType.WEEKLY, 'Weekly'],
    [ReportFrequencyType.MONTHLY, 'Monthly'],
    [ReportFrequencyType.QUARTERLY, 'Quarterly'],
    [ReportFrequencyType.YEARLY, 'Yearly'],
])

export const ReportFrequencyDayOfTheWeekLabel = new Map<ReportFrequencyDayOfTheWeek, string>([
    [ReportFrequencyDayOfTheWeek.SUNDAY, 'Sunday'],
    [ReportFrequencyDayOfTheWeek.MONDAY, 'Monday'],
    [ReportFrequencyDayOfTheWeek.TUESDAY, 'Tuesday'],
    [ReportFrequencyDayOfTheWeek.WEDNESDAY, 'Wednesday'],
    [ReportFrequencyDayOfTheWeek.THURSDAY, 'Thursday'],
    [ReportFrequencyDayOfTheWeek.FRIDAY, 'Friday'],
    [ReportFrequencyDayOfTheWeek.SATURDAY, 'Saturday'],
])

export const ReportOutputStyleLabel = new Map<ReportOutputStyle, string>([
    [ReportOutputStyle.EXCEL, 'Excel'],
    [ReportOutputStyle.CSV, 'CSV'],
])

export const TimeConfigTypeLabel = new Map<TimeConfigType, string>([
    [TimeConfigType.CALENDAR_WEEKS_BEFORE, 'Calendar week before report is run'],
    [TimeConfigType.DAYS_BEFORE, 'Days before when report is run'],
    [TimeConfigType.DATE, 'Fixed date'],
    // [TimeConfigType.MONTH, 'Month'],
    [TimeConfigType.MONTHS_BEFORE, 'Months before report is run'],
    // [TimeConfigType.ASDA_WEEKS_BEFORE, 'Relative Asda week before report is run'],
    // [TimeConfigType.ASDA_WEEK, 'Specific Asda Week'],
    // [TimeConfigType.WALMART_WEEK, 'Specific Walmart Week'],
    [TimeConfigType.TODAY, 'Today (when report is run)'],
    // [TimeConfigType.WHOLE_YEAR, 'Whole Year'],
])

export const TimeConfigTypeFutureLabel = new Map<TimeConfigType, string>([
    [TimeConfigType.CALENDAR_WEEKS_BEFORE, 'Calendar week after report is run'],
    [TimeConfigType.DAYS_BEFORE, 'Days after when report is run'],
    [TimeConfigType.DATE, 'Fixed date'],
    // [TimeConfigType.MONTH, 'Month'],
    [TimeConfigType.MONTHS_BEFORE, 'Months after report is run'],
    // [TimeConfigType.ASDA_WEEKS_BEFORE, 'Relative Asda week after report is run'],
    // [TimeConfigType.ASDA_WEEK, 'Specific Asda Week'],
    // [TimeConfigType.WALMART_WEEK, 'Specific Walmart Week'],
    [TimeConfigType.TODAY, 'Today (when report is run)'],
    // [TimeConfigType.WHOLE_YEAR, 'Whole Year'],
])

export const NodeFormatTypeLabel = new Map<NodeFormatType, string>([
    [NodeFormatType.DEFAULT, 'Default'],
    [NodeFormatType.CURRENCY, 'Currency'],
    [NodeFormatType.PERCENTAGE, 'Percentage'],
    [NodeFormatType.STRING, 'String'],
    [NodeFormatType.MULTILINE_STRING, 'Multiline String'],
    [NodeFormatType.DECIMAL, 'Decimal'],
])

export const TimeConfigYearTypeLabel = new Map<TimeConfigYearType, string>([
    [TimeConfigYearType.CALENDAR, 'Calendar'],
    [TimeConfigYearType.FISCAL, 'Fiscal'],
])

export const MonthOfTheYearLabel = new Map<number, string>([
    [1, 'January'],
    [2, 'February'],
    [3, 'March'],
    [4, 'April'],
    [5, 'May'],
    [6, 'June'],
    [7, 'July'],
    [8, 'August'],
    [9, 'September'],
    [10, 'October'],
    [11, 'November'],
    [12, 'December'],
])

export const TemplateStagesLabel = new Map<TemplateStageType, string>([
    [TemplateStageType.ADJUSTMENTS, 'Adjustments'],
    [TemplateStageType.FILTERS, 'Filters'],
    [TemplateStageType.COLUMNS, 'Columns'],
    [TemplateStageType.CUSTOMERS, 'Customers'],
    [TemplateStageType.DEPARTMENTS, 'Departments'],
    [TemplateStageType.ITEMS, 'Items'],
    [TemplateStageType.LOCATIONS, 'Locations'],
    [TemplateStageType.EVENTS, 'Events'],
    [TemplateStageType.OPTIONS, 'Options'],
    [TemplateStageType.POS, 'POS'],
    [TemplateStageType.REMITTANCES, 'Remittances'],
    [TemplateStageType.REPORT_FILTERS, 'Report Filters'],
    [TemplateStageType.SALES_TYPES, 'Sales Types'],
    [TemplateStageType.TIMES, 'Times'],
])

export const UploadMetadataTypeLabel = new Map<UploadMetadataType, string>([
    [UploadMetadataType.COLUMNS, 'Columns'],
    [UploadMetadataType.ITEMFILTERS, 'Item Filters'],
])

export const FolderOutputStyleLabel = new Map<FolderOutputStyle, string>([
    [FolderOutputStyle.CSV, 'CSV'],
    [FolderOutputStyle.EXCEL, 'Excel'],
    [FolderOutputStyle.EXCEL_ZIP, 'Excel (ZIP)'],
])

export const ReportRunTypeLabel = new Map<ReportRunType, string>([
    [ReportRunType.SCHEDULED, 'Scheduled'],
    [ReportRunType.RUN_NOW, 'Run Now'],
])

export const UserActivityLabel = new Map<UserActivity, string>([
    [UserActivity.LOGIN, 'Login'],
    [UserActivity.CREATE_REPORT, 'Create Report'],
    [UserActivity.DELETE_REPORT, 'Delete Report'],
    [UserActivity.RUN_REPORT, 'Run Report'],
    [UserActivity.DOWNLOAD, 'Download'],
    [UserActivity.SHARE_REPORT, 'Share Report'],
    [UserActivity.RESCHEDULE_REPORT, 'Reschedule Report'],
    [UserActivity.MOVE_REPORT, 'Move Report'],
    [UserActivity.UPDATED_REPORT, 'Updated Report'],
    [UserActivity.DELETE_RUN, 'Delete Run'],
    // [UserActivity.ADMIN_RUN_SQL, 'Admin Run SQL'],
    // [UserActivity.ADMIN_UPLOAD_METADATA, 'Admin Upload Metadata'],
    // [UserActivity.ADMIN_MODIFY_USER, 'Admin Modify User'],
    // [UserActivity.ADMIN_ADD_USER, 'Admin Add User'],
    // [UserActivity.ADMIN_DELETE_USER, 'Admin Delete User'],
    // [UserActivity.ADMIN_PREHEAT_DATAMART, 'Admin Preheat Datamart'],
    // [UserActivity.ADMIN_MODIFY_NODE, 'Admin Modify Node'],
])

