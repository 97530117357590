import { Component, OnDestroy, OnInit } from '@angular/core'
import { AppBaseComponent } from '@common/components'
import { ConfigKey, IBreadcrumb } from '@shared/models'
import { ApiService, BreadcrumbService, LastReportRunsService } from '@shared/services'
import { isUndefined } from 'lodash-es'
import { delay, takeUntil } from 'rxjs/operators'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppBaseComponent implements OnInit, OnDestroy {

    helpUrl = ''
    breadcrumbs: IBreadcrumb[] = []
    defaultPoolingIntervalInMs = 2000
    noticeContent: string

    constructor(
        private breadcrumbService: BreadcrumbService,
        private apiService: ApiService,
        private lastReportRunsService: LastReportRunsService,
    ) {
        super()
    }

    ngOnInit() {
        this.startLastReportRunPolling()
        this.watchBreadcrumbs()
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy()
        this.lastReportRunsService.stopIntervalPooling()
    }

    startLastReportRunPolling() {
        this.apiService.fetchConfig()
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((configResponse) => {
                const configValueTimer = configResponse.find((config) => config.key === ConfigKey.RUNS_REFRESH_TIMER)?.value ?? undefined
                if (configValueTimer !== 0) {
                    const intervalInMs = isUndefined(configValueTimer) ? this.defaultPoolingIntervalInMs : Number(configValueTimer)
                    this.lastReportRunsService.startIntervalPooling(intervalInMs)
                }

                this.noticeContent = configResponse.find((config) => config.key === ConfigKey.NOTICE)?.value as string ?? null
                this.helpUrl = configResponse.find((config) => config.key === ConfigKey.DSS_HELP_URL)?.value as string ?? ''
            })
    }

    watchBreadcrumbs() {
        this.breadcrumbService.breadcrumbs$
            .pipe(
                delay(0),
                takeUntil(this.destroyed$),
            )
            .subscribe((breadcrumbs) => {
                this.breadcrumbs = breadcrumbs
            })
    }

}
