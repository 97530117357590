export interface INodeLeaf {
    id: number
    children?: INodeLeaf[] | null
}

export interface ITemplate {
    id: number
    parentId?: number
    name: string
    description?: string
    isFolder: boolean
    children?: ITemplate[] | null
    noStages?: boolean | null
    timeType?: TemplateTimeType | null
}

export interface ITemplateStage {
    desc: string
    isMandatory: boolean
    stageType: TemplateStageType
}

export enum TemplateTimeType {
    PAST = 'PAST',
    FUTURE = 'FUTURE',
}

export enum TemplateStageType {
    COLUMNS = 'COLUMNS',
    ITEMS = 'ITEMS',
    LOCATIONS = 'LOCATIONS',
    TIMES = 'TIMES',
    EVENTS = 'EVENTS',
    OPTIONS = 'OPTIONS',
    ADJUSTMENTS = 'ADJUSTMENTS',
    REMITTANCES = 'REMITTANCES',
    FILTERS = 'FILTERS',
    CUSTOMERS = 'CUSTOMERS',
    DEPARTMENTS = 'DEPARTMENTS',
    REPORT_FILTERS = 'REPORT_FILTERS',
    POS = 'POS',
    SALES_TYPES = 'SALES_TYPES',
}

export interface ITemplateStageSection {
    id: number
    name: string
    type: TemplateStageSectionType
    nodes: ITemplateStageSectionNode[]
}

export enum TemplateStageSectionType {
    COLUMN_PICKER = 'COLUMN_PICKER',
    HIERARCHY_FILTER = 'HIERARCHY_FILTER',
    CUSTOM_FILTER = 'CUSTOM_FILTER',
    PREDEFINED_FILTER = 'PREDEFINED_FILTER',
    CUSTOM_GROUPS = 'CUSTOM_GROUPS',
}

export interface ITemplateStageSectionNode {
    id: number
    parentId: number
    name: string
    desc: string
    formatHint: string
    isFolder: boolean
    isSelectable: boolean
    validationRegex: string
    dataType: TemplateStageSectionNodeDataType
    children?: ITemplateStageSectionNode[] | null
    availableFilters: TemplateStageSectionNodeFilter[]
    sectionId: number
    childrenCount: number
    path?: string
    datamartTable?: string
    datamartColumn?: string
    datamartValue?: string
    measureId?: number
    measureName?: string
    measureTables?: string
    measureValue?: string
    measureColumns?: string
    formatType?: NodeFormatType
    visible?: boolean
    isDatamartError?: boolean
    hasDebugInfo?: boolean
}

export enum TemplateStageSectionNodeFilter {
    ONE_OF = 'ONE_OF',
    NOT_ONE_OF = 'NOT_ONE_OF',
    BETWEEN = 'BETWEEN',
    NOT_BETWEEN = 'NOT_BETWEEN',
    LIKE = 'LIKE',
    NOT_LIKE = 'NOT_LIKE',
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN',
    LESS_THAN = 'LESS_THAN',
    LESS_OR_EQUAL_THAN = 'LESS_OR_EQUAL_THAN',
}

export enum TemplateStageSectionNodeDataType {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    DECIMAL = 'DECIMAL',
    DATE = 'DATE',
    TIME = 'TIME',
    DATETIME = 'DATETIME',
}

export enum UploadMetadataType {
    COLUMNS = 'COLUMNS',
    ITEMFILTERS = 'ITEMFILTERS',
}

export interface IReportResponse {
    id: number
    name: string
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
    templateId: number
    folderId: number
    templateName: string
    outputStyle: ReportOutputStyle
    isShared: boolean
    isScheduled: boolean
    lastScheduledRunAt: string
    scheduleStart: string
    scheduleEnd: string
    frequencyType: ReportFrequencyType
    frequencyOnceDate: string
    frequencyDayOfTheWeek: ReportFrequencyDayOfTheWeek
    frequencyDayOfTheMonth: number
    frequencyMonth: number
    config: IReportConfigStage[]
    timeConfig: IReportTimeConfig[]
    templateTimeType: TemplateTimeType
    isHidden: boolean
}

export interface IReportRunResponse {
    id: number
    reportId: number
    reportName: string
    createdBy: string
    createdAt: string
    runType: ReportRunType
    status: ReportRunStatus
    hadError: boolean
    queryStartedAt: string
    queryCompletedAt: string
    outputFile: IRunNowOutputFile[]
    retryCount?: number
    rowCount?: number
    lastRetryFailedAt?: string
    formatStartedAt?: string
    formatCompletedAt?: string
    formattedRows?: number
}

export interface ILastReportRunResponse {
    id: number
    name: string
    createdAt: string
    status: ReportRunStatus
    completedAt?: string
}

export enum ReportRunType {
    SCHEDULED = 'SCHEDULED',
    RUN_NOW = 'RUN_NOW',
}

export enum ReportRunStatus {
    QUEUED = 'QUEUED',
    IN_PROGRESS_SQL = 'IN_PROGRESS_SQL',
    WAITING_FORMAT = 'WAITING_FORMAT',
    IN_PROGRESS_FORMAT = 'IN_PROGRESS_FORMAT',
    COMPLETED = 'COMPLETED',
}

export interface IReportTimeConfig {
    id?: string
    name: string
    start: IReportTimeConfigPoint
    end?: IReportTimeConfigPoint
}

export interface IReportTimeConfigPoint {
    type?: TimeConfigType
    yearType?: TimeConfigYearType
    yearsBefore?: number
    date?: string
    month?: number
    before?: number
    walmartWeek?: string
    asdaWeek?: number
    effectiveDate?: string
}

export enum ReportTimeConfigPointField {
    type = 'type',
    yearType = 'yearType',
    yearsBefore = 'yearsBefore',
    date = 'date',
    month = 'month',
    before = 'before',
    walmartWeek = 'walmartWeek',
    asdaWeek = 'asdaWeek',
    effectiveDate = 'effectiveDate',
}

export enum ReportTimeConfigPointPosition {
    START = 'START',
    END = 'END',
}

export enum TimeConfigType {
    TODAY = 'TODAY',
    DATE = 'DATE',
    DAYS_BEFORE = 'DAYS_BEFORE',
    CALENDAR_WEEKS_BEFORE = 'CALENDAR_WEEKS_BEFORE',
    ASDA_WEEKS_BEFORE = 'ASDA_WEEKS_BEFORE',
    MONTH = 'MONTH',
    MONTHS_BEFORE = 'MONTHS_BEFORE',
    WALMART_WEEK = 'WALMART_WEEK',
    ASDA_WEEK = 'ASDA_WEEK',
    WHOLE_YEAR = 'WHOLE_YEAR',
}

export enum TimeConfigYearType {
    FISCAL = 'FISCAL',
    CALENDAR = 'CALENDAR',
}

export interface IReportRunDownloadLink {
    url: string
}

export interface IRunNowOutputFile {
    id: number
    type: FolderOutputStyle
    createdAt: string
    filesize: number
    filename?: string
}

export interface IConfigResponse {
    key: ConfigKey
    value: string | number
}

export enum ConfigKey {
    DSS_HELP_URL = 'DSS_HELP_URL',
    POWERBI_URL = 'POWERBI_URL',
    RUNS_REFRESH_TIMER = 'RUNS_REFRESH_TIMER',
    NOTICE = 'NOTICE',
    NOTICE_BOARD = 'NOTICE_BOARD',
    NOTICE_BOARD_FOOTER_SUPPLIER = 'NOTICE_BOARD_FOOTER_SUPPLIER',
    NOTICE_BOARD_FOOTER_ASDA = 'NOTICE_BOARD_FOOTER_ASDA',
}

export interface IFolderNode {
    id: number
    parentId: number
    name: string
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
    reports: IFolderReport[] | null
    children: IFolderNode[] | null
    reportData?: IFolderReport
    path?: string
}

export interface IFolderReport {
    id: number
    name: string
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
    templateId: number
    templateName: string
    outputStyle: FolderOutputStyle
    isShared: boolean
    nextRunOn?: string
    folderId: number
    runStats?: number[]
    sharedBy?: string
    isHidden?: boolean
}

export enum FolderOutputStyle {
    CSV = 'CSV',
    EXCEL = 'EXCEL',
    CSV_ZIP = 'CSV_ZIP',
    EXCEL_ZIP = 'EXCEL_ZIP',
}

export interface IFolderCreateRequest {
    parentId: number
    name: string
}

export interface IFolderEditRequest {
    name: string
}

export interface IReportRequest {
    id?: number
    name: string
    templateId: number
    folderId?: number
    outputStyle?: ReportOutputStyle
    isScheduled?: boolean
    scheduleStart?: string
    scheduleEnd?: string
    frequencyType?: ReportFrequencyType
    frequencyOnceDate?: string
    frequencyDayOfTheWeek?: ReportFrequencyDayOfTheWeek
    frequencyDayOfTheMonth?: number
    frequencyMonth?: number
    config: IReportConfigStage[]
    timeConfig: IReportTimeConfig[]
    isHidden?: boolean
}

export interface IReportEditRequest {
    name: string
    folderId?: number
    outputStyle?: ReportOutputStyle
    isScheduled?: boolean
    scheduleStart?: string
    scheduleEnd?: string
    frequencyType?: ReportFrequencyType
    frequencyOnceDate?: string
    frequencyDayOfTheWeek?: ReportFrequencyDayOfTheWeek
    frequencyDayOfTheMonth?: number
    frequencyMonth?: number
    config: IReportConfigStage[]
    timeConfig: IReportTimeConfig[]
}

export interface IReportNode {
    id: number
    filterOperation?: ReportNodeFilterOperation
    filters?: IReportNodeFilter[]
    name?: string
    displayName?: string
    order?: number
    nodePath?: IReportNodePath[]
}

export interface IReportNodePath {
    id: number
    name: string
}

export enum ReportNodeFilterOperation {
    AND = 'AND',
    OR = 'OR',
}

export enum ReportFrequencyType {
    NONE = 'NONE',
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUATERLY',
    YEARLY = 'YEARLY',
}

export enum ReportFrequencyDayOfTheWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export enum NodeFormatType {
    DEFAULT = 'DEFAULT',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE',
    STRING = 'STRING',
    MULTILINE_STRING = 'MULTILINE_STRING',
    DECIMAL = 'DECIMAL',
}

export enum ReportOutputStyle {
    EXCEL = 'EXCEL',
    CSV = 'CSV',
}

export interface IReportNodeFilter {
    filterType: TemplateStageSectionNodeFilter
    values: string[]
}

export interface IReportSection {
    id: number
    name: string
    nodes: IReportNode[]
}

export interface IReportConfigStage {
    stage: TemplateStageType
    sections: IReportSection[]
    stageFilterOperation?: ReportNodeFilterOperation
}

export interface IReportEditFolderRequest {
    folderId: number
}

export interface IReportEditNextRunOnRequest {
    nextRunOn: string
}

export interface IReportSharedUser {
    user: string
}

export interface IEditNodeRequest {
    datamartTable?: string
    datamartColumn?: string
    datamartValue?: string
    measureId?: number
    measureName?: string
    measureTables?: string
    measureValue?: string
    measureColumns?: string
    formatType?: NodeFormatType
}

export interface IRunSqlResponse {
    headers: string[]
    rows: string[][]
}

export interface IUsersResponse {
    users: IUser[]
}

export interface IUser {
    email: string
    isDeleted: boolean
    isPowerUser: boolean
    vendorId: string
    lastSeenAt?: string
    onboarded?: boolean
}

export interface IStatusDashboardResponse {
    runnerQueue: IStatusReportRunResponse[]
    runnerInProgress: IStatusReportRunResponse[]
    formatterQueue: IStatusReportRunResponse[]
    formatterInProgress: IStatusReportRunResponse[]
    completed?: IStatusReportRunResponse[]
    failed?: IStatusReportRunResponse[]
}

export interface IStatusReportRunResponse {
    id: number
    reportId: number
    reportName: string
    createdBy: string
    createdAt: string
    errorLog: string
    hadError: boolean
    sqlQuery: string
    status: ReportRunStatus
    runType: ReportRunType
    queryCompletedAt: string
    queryStartedAt: string
    formatCompletedAt: string
    formatStartedAt: string
    isHidden: boolean
    retryCount: number
    lastRetryFailedAt: string
    rowCount: number
    columnCount: number
    outputFile?: IRunNowOutputFile[]
}

export enum UserActivity {
    LOGIN = 'LOGIN',
    CREATE_REPORT = 'CREATE_REPORT',
    DELETE_REPORT = 'DELETE_REPORT',
    RUN_REPORT = 'RUN_REPORT',
    DOWNLOAD = 'DOWNLOAD',
    SHARE_REPORT = 'SHARE_REPORT',
    RESCHEDULE_REPORT = 'RESCHEDULE_REPORT',
    MOVE_REPORT = 'MOVE_REPORT',
    UPDATED_REPORT = 'UPDATED_REPORT',
    DELETE_RUN = 'DELETE_RUN',
    ADMIN_RUN_SQL = 'ADMIN_RUN_SQL',
    ADMIN_UPLOAD_METADATA = 'ADMIN_UPLOAD_METADATA',
    ADMIN_MODIFY_USER = 'ADMIN_MODIFY_USER',
    ADMIN_ADD_USER = 'ADMIN_ADD_USER',
    ADMIN_DELETE_USER = 'ADMIN_DELETE_USER',
    ADMIN_PREHEAT_DATAMART = 'ADMIN_PREHEAT_DATAMART',
    ADMIN_MODIFY_NODE = 'ADMIN_MODIFY_NODE',
}

export interface IUserActivityResponse {
    userActivities: IUserActivity[]
}

export interface IUserActivity {
    activity: UserActivity
    email: string
    id: number
    reference: string
    timestamp: string
}

export interface IReportDetail {
    sqlQuery?: string
    errorLog?: string
    queryStartedAt: string
    queryCompletedAt: string
    formatStartedAt?: string
    formatCompletedAt?: string
}
