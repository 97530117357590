import { Pipe, PipeTransform } from '@angular/core'
import { isString } from 'lodash-es'


@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {

    transform(startDate: string | Date, endDate: string | Date): string {
        if (!startDate || !endDate) {
            return ''
        }

        const start = isString(startDate) ? new Date(startDate) : startDate
        const end = isString(endDate) ? new Date(endDate) : endDate

        const durationInSeconds = Math.abs((end.getTime() - start.getTime()) / 1000)

        const minutes = Math.floor(durationInSeconds / 60)
        const seconds = Math.floor(durationInSeconds % 60)

        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60

        if (hours > 0) {
            const formattedHours = this.padNumber(hours)
            const formattedMinutes = this.padNumber(remainingMinutes)
            const formattedSeconds = this.padNumber(seconds)

            return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`
        }

        const formattedMinutes = this.padNumber(minutes)
        const formattedSeconds = this.padNumber(seconds)

        return `${formattedMinutes}m ${formattedSeconds}s`
    }

    private padNumber(num: number): string {
        return num < 10 ? `0${num}` : num.toString()
    }

}
