import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'


@Component({
    selector: 'app-asda-message-popup',
    templateUrl: './asda-message-popup.component.html',
})
export class AsdaMessagePopupComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string; title: string }) {
    }

}
