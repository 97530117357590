<div class="d-flex justify-content-between align-items-center p-4">
    <h4>Run SQL Query</h4>
    <button mat-icon-button
            aria-label="close dialog"
            mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="pt-3 overflow-hidden">
    <form class="d-flex flex-column gap-2"
          [formGroup]="formGroup"
          (submit)="onSubmit()">

        <mat-form-field class="w-100"
                        appearance="outline">
            <mat-label>SQL Query</mat-label>
            <textarea matInput
                      [style.font-family]="'monospace'"
                      rows="4"
                      [formControlName]="'query'">
                        </textarea>
        </mat-form-field>

        <mat-checkbox [formControlName]="'asCsv'"
                      color="primary">
            Download as CSV
        </mat-checkbox>
        <button mat-flat-button
                class="mt-3 text-white"
                type="button"
                [disabled]="!formGroup.valid || isLoading"
                color="primary"
                (click)="onSubmit()">
            {{isLoading ? 'Processing...' : 'Submit' }}
        </button>
    </form>
    <mat-progress-bar *ngIf="isLoading"
                      mode="indeterminate"></mat-progress-bar>

    <div *ngIf="errorMessage"
         class="overflow-auto w-100 mt-3">
        <div class="d-flex gap-3 flex-column mt-5 position-relative">
            <h5>Error response:</h5>
            <pre [style.white-space]="'pre-wrap'">{{ errorMessage | json  }}</pre>
            <button mat-button
                    class="position-absolute top-0 bg-white mat-elevation-z2"
                    [style.right.px]="35"
                    (click)="onCopyErrorMessage(errorMessage)">
                <mat-icon class="material-symbols-outlined text-body">content_copy</mat-icon>
                <span>{{ copyErrorMessagePlaceholder }}</span>
            </button>
        </div>
    </div>

    <div class="overflow-auto w-100 mt-3">
        <table mat-table
               matSort
               [dataSource]="dataSource"
               class="d-block mat-elevation-z0"
               [style.max-height.px]="300">

            <ng-container *ngFor="let column of displayedColumns; let colIndex = index"
                          [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header>{{ column }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> <span class="font-monospace">{{ element[column] }}</span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
    </div>
</mat-dialog-content>