import { Pipe, PipeTransform } from '@angular/core'


@Pipe({
    name: 'durationSeconds',
})
export class DurationSecondsPipe implements PipeTransform {

    transform(durationInSeconds: number): string {
        if (!durationInSeconds || durationInSeconds <= 0) {
            return '00m 00s'
        }

        const minutes = Math.floor(durationInSeconds / 60)
        const seconds = Math.floor(durationInSeconds % 60)

        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60

        if (hours > 0) {
            const formattedHours = this.padNumber(hours)
            const formattedMinutes = this.padNumber(remainingMinutes)
            const formattedSeconds = this.padNumber(seconds)

            return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`
        }

        const formattedMinutes = this.padNumber(minutes)
        const formattedSeconds = this.padNumber(seconds)

        return `${formattedMinutes}m ${formattedSeconds}s`
    }

    private padNumber(num: number): string {
        return num < 10 ? `0${num}` : num.toString()
    }


}
