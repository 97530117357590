import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { STATUS_DASHBOARD_CACHED_RESPONSE_KEY, STATUS_DASHBOARD_ETAG_KEY } from '@shared/constants'
import {
    ICalendarData,
    IReportRequest,
    IReportRunResponse,
    IReportResponse,
    IConfigResponse,
    IFolderNode,
    IReportEditRequest,
    IFolderCreateRequest,
    IFolderEditRequest,
    ITemplate,
    ITemplateStageSection,
    ITemplateStage,
    TemplateStageType,
    IReportSharedUser,
    IReportRunDownloadLink,
    ICalendarRequest,
    IEditNodeRequest,
    IReportEditFolderRequest,
    IReportEditNextRunOnRequest,
    IRunSqlResponse,
    IUser,
    IUsersResponse,
    UploadMetadataType,
    ILastReportRunResponse,
    IStatusDashboardResponse,
    IUserActivityResponse,
    IReportDetail,
} from '@shared/models'
import { Observable, from, throwError } from 'rxjs'
import { catchError, map, retry } from 'rxjs/operators'

import { ConfigService } from './config.service'


@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) { }

    get apiUrl(): string {
        return this.configService.apiEndpoint
    }

    httpHeader = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    }

    //
    // CALENDAR
    //

    fetchCalendar(request: ICalendarRequest): Observable<ICalendarData[]> {
        return this.httpClient.get<ICalendarData[]>(`${this.apiUrl}/calendar`, { params: { ...request } })
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    //
    // CONFIG
    //

    fetchConfig(): Observable<IConfigResponse[]> {
        return this.httpClient.get<IConfigResponse[]>(`${this.apiUrl}/config`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    //
    // FOLDERS
    //

    deleteFolder(folderId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/folders/${folderId}`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    createFolder(request: IFolderCreateRequest): Observable<IFolderNode> {
        return this.httpClient.post<IFolderNode>(`${this.apiUrl}/folders`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    editFolder(folderId: number, request: IFolderEditRequest): Observable<IFolderNode> {
        return this.httpClient.patch<IFolderNode>(`${this.apiUrl}/folders/${folderId}`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchFolders(): Observable<IFolderNode> {
        return this.httpClient.get<IFolderNode>(`${this.apiUrl}/folders`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    //
    // REPORTS
    //

    createReport(request: IReportRequest): Observable<IReportResponse> {
        return this.httpClient.post<IReportResponse>(`${this.apiUrl}/reports`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchReports(): Observable<IReportResponse[]> {
        return this.httpClient.get<IReportResponse[]>(`${this.apiUrl}/reports`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchReport(reportId: number): Observable<IReportResponse> {
        return this.httpClient.get<IReportResponse>(`${this.apiUrl}/reports/${reportId}`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }


    fetchReportSharedUsers(reportId: number): Observable<IReportSharedUser[]> {
        return this.httpClient.get<IReportSharedUser[]>(`${this.apiUrl}/reports/${reportId}/sharedWith`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    shareReport(reportId: number, request: IReportSharedUser): Observable<IReportSharedUser> {
        return this.httpClient.post<IReportSharedUser>(`${this.apiUrl}/reports/${reportId}/share`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    editReport(reportId: number, request: IReportEditRequest): Observable<IReportResponse> {
        return this.httpClient.put<IReportResponse>(`${this.apiUrl}/reports/${reportId}`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    editReportFolder(reportId: number, request: IReportEditFolderRequest): Observable<IReportResponse> {
        return this.httpClient.post<IReportResponse>(`${this.apiUrl}/reports/${reportId}/folderId`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    editReportNextRunOn(reportId: number, request: IReportEditNextRunOnRequest): Observable<IReportResponse> {
        return this.httpClient.post<IReportResponse>(`${this.apiUrl}/reports/${reportId}/nextRunOn`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    runNowReport(reportId: number): Observable<IReportRunResponse> {
        return this.httpClient.post<IReportRunResponse>(`${this.apiUrl}/reports/${reportId}/runNow`, {})
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchLastReportRuns(): Observable<ILastReportRunResponse> {
        return this.httpClient.get<ILastReportRunResponse>(`${this.apiUrl}/lastRuns`)
    }

    deleteReportRun(reportId: number, runId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/runs/${runId}`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    deleteReport(reportId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/reports/${reportId}`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchReportRuns(reportId: number): Observable<IReportRunResponse[]> {
        return this.httpClient.get<IReportRunResponse[]>(`${this.apiUrl}/reports${reportId}/runs`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    //
    // RUNS
    //

    fetchRuns(): Observable<IReportRunResponse[]> {
        return this.httpClient.get<IReportRunResponse[]>(`${this.apiUrl}/runs`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchRunFileDownloadLink(runId: number, outputId: number): Observable<IReportRunDownloadLink> {
        return this.httpClient.get<IReportRunDownloadLink>(`${this.apiUrl}/runs/${runId}/outputFiles/${outputId}/downloadLink`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchRunFileDownload(runId: number, outputId: number): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${this.apiUrl}/runs/${runId}/outputFiles/${outputId}/download`, { responseType: 'blob', observe: 'response' })
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    //
    // TEMPLATES
    //

    fetchTemplates(): Observable<ITemplate[]> {
        return this.httpClient.get<ITemplate[]>(`${this.apiUrl}/templates`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchTemplateSections(templateId: number, stageType: TemplateStageType): Observable<ITemplateStageSection[]> {
        return this.httpClient.get<ITemplateStageSection[]>(`${this.apiUrl}/templates/${templateId}/stages/${stageType}`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchTemplateStages(templateId: number): Observable<ITemplateStage[]> {
        return this.httpClient.get<ITemplateStage[]>(`${this.apiUrl}/templates/${templateId}/stages`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    editNode(nodeId: number, request: IEditNodeRequest): Observable<IEditNodeRequest> {
        return this.httpClient.put<IEditNodeRequest>(`${this.apiUrl}/manage/nodes/${nodeId}`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchUsers(): Observable<IUsersResponse> {
        return this.httpClient.get<IUsersResponse>(`${this.apiUrl}/manage/users`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    fetchMe(): Observable<IUser> {
        return this.httpClient.get<IUser>(`${this.apiUrl}/me`)
            .pipe(
                retry(1),
            )
    }

    createUser(request: IUser): Observable<IUser> {
        return this.httpClient.post<IUser>(`${this.apiUrl}/manage/users`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    editUser(userEmail: string, request: IUser): Observable<IUser> {
        return this.httpClient.put<IUser>(`${this.apiUrl}/manage/users/${userEmail}`, request)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    deleteUser(userEmail: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/manage/users/${userEmail}`)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    runSqlQuery(sqlQuery: string, asCsv: boolean): Observable<IRunSqlResponse | Blob> {
        return asCsv
            ? this.httpClient.post(`${this.apiUrl}/manage/runsql`, sqlQuery, { params: { rawResult: 1 }, responseType: 'blob' })
                .pipe(
                    catchError((errorResponse: HttpErrorResponse) => {
                        return from(errorResponse.error.text() as Promise<string>)
                            .pipe(
                                map((errorResponse) => {
                                    throw JSON.parse(errorResponse)
                                }),
                            )
                    }),
                )
            : this.httpClient.post<IRunSqlResponse>(`${this.apiUrl}/manage/runsql`, sqlQuery)
    }

    uploadMetadata(type: UploadMetadataType, file: File): Observable<void> {
        const formData = new FormData()
        formData.append('file', file)
        return this.httpClient.post<void>(`${this.apiUrl}/manage/uploadmetadata/${type}`, formData)
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }


    fetchRunDetails(runId: number): Observable<IReportDetail> {
        // temp fix
        // return this.httpClient.get<IReportDetail>(`${this.apiUrl}/manage/runDetails/${runId}`)
        return this.httpClient.get<IReportDetail>(`${this.apiUrl}/manage/statusDashboardDetails/${runId}`)
            .pipe(
                catchError(this.httpErrorHandler),
            )
    }

    fetchStatusDashboard(date: string): Observable<IStatusDashboardResponse> {
        const savedETagKeyData = localStorage.getItem(STATUS_DASHBOARD_ETAG_KEY)
        const headers = new HttpHeaders()

        if (savedETagKeyData) {
            // Disable caching for now - causing issues,
            // headers = headers.set('If-None-Match', savedETagKeyData)
        }

        return this.httpClient.get<IStatusDashboardResponse>(`${this.apiUrl}/manage/statusDashboard`, { headers, observe: 'response', params: { date } })
            .pipe(
                map(response => {
                    if (response.status === 304) {
                        const cachedResponseData = localStorage.getItem(STATUS_DASHBOARD_CACHED_RESPONSE_KEY)
                        if (!cachedResponseData) {
                            throw new Error('Expected cache entry not found')
                        }
                        return JSON.parse(cachedResponseData) as IStatusDashboardResponse
                    }
                    else {
                        const newETagHeader = response.headers.get('ETag')
                        const bodyStr = JSON.stringify(response.body)

                        if (bodyStr.length < 5000000 && newETagHeader) { // Limit 5 MB for response
                            localStorage.setItem(STATUS_DASHBOARD_ETAG_KEY, newETagHeader)
                            localStorage.setItem(STATUS_DASHBOARD_CACHED_RESPONSE_KEY, bodyStr)
                        }

                        return response.body as IStatusDashboardResponse
                    }
                }),
                catchError(this.httpErrorHandler),
            )
    }

    fetchUserActivities(date: string): Observable<IUserActivityResponse> {
        return this.httpClient.get<IUserActivityResponse>(`${this.apiUrl}/manage/usersActivity`, { params: { date } })
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    preheatDatamart(): Observable<void> {
        return this.httpClient.post<void>(`${this.apiUrl}/manage/preheatdatamart`, {})
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    syncAll(): Observable<void> {
        return this.httpClient.post<void>(`${this.apiUrl}/manage/sync`, {})
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    syncTable(tableName: string): Observable<void> {
        return this.httpClient.post<void>(`${this.apiUrl}/manage/sync/${tableName}`, {})
            .pipe(
                retry(1),
                catchError(this.httpErrorHandler),
            )
    }

    httpErrorHandler = (error: HttpErrorResponse) => {
        let msg = ''
        if (error.error instanceof ErrorEvent) {
            // client side error
            msg = error.error.message
        }
        else {
            // server side error
            msg = `Error Code: ${error.status}\nMessage: ${error.message}`
        }
        return throwError(() => msg)
    }

}
