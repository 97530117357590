import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { MsalService } from '@azure/msal-angular'
import { AppBaseComponent } from '@common/components'
import { environment } from '@environments/environment'
import { AsdaDateSelectDialogComponent } from '@shared/components'
import { DATE_TIME_FORMAT } from '@shared/constants'
import { API_DATE_FORMAT, ILastReportRunResponse, IProfile } from '@shared/models'
import { ApiService, ConfigService, LastReportRunsService, ProfileService } from '@shared/services'
import * as dayjs from 'dayjs'
import { catchError, takeUntil, throwError } from 'rxjs'

import { AsdaDirectSqlDialogComponent } from '../asda-direct-sql-dialog/asda-direct-sql-dialog.component'
import { AsdaUploadConfigFileDialogComponent } from '../asda-upload-config-file-dialog/asda-upload-config-file-dialog.component'


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatSlideToggleModule,
        MatMenuModule,
        RouterModule,
    ],
})

export class NavbarComponent extends AppBaseComponent implements OnInit {

    @Input() helpUrl: string

    lastReportRun?: ILastReportRunResponse
    profile: IProfile
    copyPlaceholder = 'Copy ASDA Week'
    asdaWeek: string
    newLastReportRun: boolean
    newLastReportRunTimeout: NodeJS.Timeout

    readonly DATE_TIME_FORMAT = DATE_TIME_FORMAT
    readonly showNewReportRunNotificationTimeout = 5 * 60 * 1000 // 5 minutes

    constructor(
        public configService: ConfigService,
        public profileService: ProfileService,
        private apiService: ApiService,
        private matDialog: MatDialog,
        private authService: MsalService,
        private lastReportRunsService: LastReportRunsService,
    ) {
        super()
        this.lastReportRunsService.lastReportRun$
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((lastReportRun) => {
                if (this.lastReportRun && this.lastReportRun?.id !== lastReportRun.id) {
                    this.showNewLastReportRunNotification()
                }
                this.lastReportRun = lastReportRun
            })
    }

    ngOnInit(): void {
        if (environment.useAuthorization) {
            this.loadProfile()
        }
        this.loadAsdaWeek()
    }

    onSwitchEndpoint(): void {
        const newEndpoint = this.configService.appConfig.apiEndpoint === this.configService.apiEndpoint
            ? this.configService.appConfig.apimEndpoint
            : this.configService.appConfig.apiEndpoint
        this.configService.setApiEndpoint(newEndpoint)
    }

    onOpenCalendarDialog(): void {
        this.matDialog.open(AsdaDateSelectDialogComponent, {
            width: '550px',
            maxHeight: '800px',
        })
    }

    onOpenDirectSqlDialog(): void {
        this.matDialog.open(AsdaDirectSqlDialogComponent, {
            width: '90vw',
            height: '90vh',
        })
    }

    onOpenUploadConfigDialog(): void {
        this.matDialog.open(AsdaUploadConfigFileDialogComponent, {
            width: '550px',
        })
    }

    onCopyAsdaWeek(text: string): void {
        this.copyPlaceholder = 'Copied!'
        navigator.clipboard.writeText(text)
            .then(() => setTimeout(() => this.copyPlaceholder = 'Copy ASDA Week', 1000))
            .catch(() => { })
    }

    onLogout(): void {
        this.authService.logout()
    }

    protected loadProfile(): void {
        this.profileService.fetchProfile()
            .pipe(
                catchError((error) => {
                    return throwError(() => error)
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((response) => {
                this.profile = response
            })
    }

    protected loadAsdaWeek(): void {
        this.apiService.fetchCalendar({
            date: dayjs().format(API_DATE_FORMAT),
        })
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((response) => {
                this.asdaWeek = response[0]?.asdaWeek.toString() ?? ''
            })
    }

    protected showNewLastReportRunNotification(): void {
        this.newLastReportRun = true
        clearTimeout(this.newLastReportRunTimeout)
        this.newLastReportRunTimeout = setTimeout(() => this.newLastReportRun = false, this.showNewReportRunNotificationTimeout)
    }

}
