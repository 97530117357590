import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AsdaSnackBarComponent } from '@shared/components'


@Injectable({
    providedIn: 'root',
})
export class NotifyService {

    constructor(private snackBar: MatSnackBar) { }

    info(message: string, action: string = 'OK', duration = 5000) {
        this.snackBar.openFromComponent(AsdaSnackBarComponent, {
            duration: duration,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['notification-info'],
            data: {
                message: message,
                icon: 'info',
                action,
            },
        })
    }

    warning(message: string, action: string = 'OK', duration = 10000) {
        this.snackBar.openFromComponent(AsdaSnackBarComponent, {
            duration: duration,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['notification-warning'],
            data: {
                message: message,
                icon: 'info',
                action,
            },
        })
    }

    error(message: string, action: string = 'OK', duration = 10000) {
        this.snackBar.open(message, action, {
            duration: duration,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['notification-error'],
        })
    }

}
