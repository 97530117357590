import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular'
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { environment } from '@environments/environment'

import { ConfigService } from '../services/config.service'


export const MSALInstanceFactory = (configService: ConfigService): IPublicClientApplication => {
    return new PublicClientApplication({
        auth: {
            clientId: configService.appConfig.clientId,
            authority: configService.appConfig.authority,
            redirectUri: '/',
            postLogoutRedirectUri: '/',
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    })
}

// provides authRequest configuration
export const MSALInterceptorConfigFactory = (configService: ConfigService): MsalInterceptorConfiguration => {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            [environment.graphEndpoint, ['user.read']],
            [configService.appConfig.apiEndpoint, [configService.appConfig.apiAuthenticationScope]],
        ]),
    } as MsalInterceptorConfiguration
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: { scopes: ['user.read'] },
    } as MsalGuardConfiguration
}

@NgModule({
})
export class MsalApplicationModule {

    static forRoot() {
        return {
            ngModule: MsalApplicationModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                    multi: true,
                },
                {
                    provide: MSAL_INSTANCE,
                    useFactory: MSALInstanceFactory,
                    deps: [ConfigService],
                },
                {
                    provide: MSAL_GUARD_CONFIG,
                    useFactory: MSALGuardConfigFactory,
                },
                {
                    provide: MSAL_INTERCEPTOR_CONFIG,
                    useFactory: MSALInterceptorConfigFactory,
                    deps: [ConfigService],
                },
                MsalService,
                MsalGuard,
                MsalBroadcastService,
            ],
            bootstrap: [MsalApplicationModule],
        }
    }

}
