<div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-content-between p-3">
        <h4>Delete</h4>
        <button mat-icon-button
                aria-label="close dialog"
                disableRipple
                mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p class="asda-fs-16 fw-400 mb-5">{{ data.message }}</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button
                mat-dialog-close>
            No, Cancel
        </button>
        <button mat-flat-button
                class="text-white"
                [mat-dialog-close]="true"
                color="primary"
                cdkFocusInitial>
            Yes, Delete
        </button>
    </mat-dialog-actions>
</div>