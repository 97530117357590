import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'


@Component({
    selector: 'app-asda-delete-popup',
    templateUrl: './asda-delete-popup.component.html',
    styleUrls: ['./asda-delete-popup.component.scss'],
})
export class AsdaDeletePopupComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {

    }

}
