<div class="d-flex align-items-center gap-1  p-2">
    <button *ngIf="breadcrumbs?.length"
            mat-button
            routerLink="home">
        Home
    </button>

    <ng-container *ngFor="let breadcrumb of breadcrumbs; let first = first">
        <span>/</span>
        <button mat-button
                [disabled]="!breadcrumb.url"
                [routerLink]="breadcrumb.url">
            {{breadcrumb.label}}
        </button>
    </ng-container>
</div>