import { MatDateFormats } from '@angular/material/core'


export const BYTES_TO_KB = 0.0009765625
export const BYTES_TO_MB = 0.00000095367432
export const DATE_FORMAT = 'dd/MM/YYYY'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
export const DATE_TIME_FORMAT_WITH_SECONDS = `${DATE_FORMAT} ${TIME_FORMAT_WITH_SECONDS}`
export const DATE_FORMAT_DAYJS = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT_DAYJS = `${DATE_FORMAT_DAYJS} ${TIME_FORMAT}`
export const DATE_REPORT_NAME_FORMAT = 'YYYY-MM-DD HH:mm'
export const X_API_KEY = 'X-API-Key'
export const APIM_KEY = 'Ocp-Apim-Subscription-Key'
export const STATUS_DASHBOARD_ETAG_KEY = 'statusDashboardETag'
export const STATUS_DASHBOARD_CACHED_RESPONSE_KEY = 'statusDashboardResponse'

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
}
