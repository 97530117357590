<div class="asda-navbar px-3">
    <div class="d-flex align-items-center gap-2">
        <img src="/assets/icons/adr-logo.png"
             alt="ADR logo"
             class="asda-logo"
             height="50" />
    </div>
    <div *ngIf="!profileService.accessDenied"
         class="d-flex align-items-center gap-3">
        <div>
            <button *ngIf="!lastReportRun"
                    mat-button
                    [disabled]="true">No report run completed yet</button>
            <button *ngIf="lastReportRun"
                    class="fw-normal"
                    mat-button
                    [routerLink]="['/report-status']"
                    (click)="newLastReportRun = false">
                <div class="d-flex flex-column asda-fs-14">
                    <span>
                        <span class="text-black-50">
                            Last report run at:
                        </span>
                        <span class="fw-500">
                            {{
                            lastReportRun.completedAt
                            ? (lastReportRun.completedAt + 'Z' | date: DATE_TIME_FORMAT)
                            : ''
                            }}
                        </span>
                    </span>
                    <span class="d-flex gap-2 align-items-center">
                        <span class="text-black-50">
                            Report:
                        </span>
                        <span class="fw-500">
                            {{ lastReportRun.name }}
                        </span>
                        <div *ngIf="newLastReportRun"
                             class="new-last-report-run-notification"
                             [matTooltip]="'New report run'"></div>
                    </span>
                </div>
            </button>
        </div>
        <div class="d-flex gap-2 align-items-center">
            <!-- Home -->
            <button class="bg-white"
                    mat-mini-fab
                    [matTooltip]="'Home'"
                    routerLink="/home"
                    routerLinkActive="bg-body-secondary">
                <mat-icon class="material-symbols-outlined text-body">home</mat-icon>
            </button>
            <!-- Add -->
            <button class="bg-white"
                    mat-mini-fab
                    [matTooltip]="'Create New Report'"
                    routerLink="/new-report"
                    routerLinkActive="bg-body-secondary">
                <mat-icon class="material-symbols-outlined text-body">add</mat-icon>
            </button>
            <!-- list -->
            <!--            <a href={{powerbiLink}}-->
            <!--               target="_blank">-->
            <!--                <button class="bg-white"-->
            <!--                        mat-mini-fab-->
            <!--                        [matTooltip]="'Power BI'"-->
            <!--                        routerLinkActive="activeLink">-->
            <!--                    <mat-icon class="material-symbols-outlined text-body">bar_chart</mat-icon>-->
            <!--                </button>-->
            <!--            </a>-->
            <!-- Time -->
            <button class="bg-white"
                    mat-mini-fab
                    [matTooltip]="'Report Status'"
                    routerLink="/report-status"
                    [queryParams]="{datePeriod: 'DAYS_7'}"
                    routerLinkActive="bg-body-secondary">
                <mat-icon class="material-symbols-outlined text-body">info</mat-icon>
            </button>
            <!-- chart -->
            <button class="bg-white"
                    mat-mini-fab
                    [matTooltip]="'My Reports'"
                    routerLink="/my-reports"
                    routerLinkActive="bg-body-secondary">
                <mat-icon class="material-symbols-outlined text-body">description</mat-icon>
            </button>

            <button *ngIf="profileService.currentUser?.isPowerUser"
                    class="bg-white"
                    mat-mini-fab
                    [matTooltip]="'User Management'"
                    routerLink="/user-management"
                    routerLinkActive="bg-body-secondary">
                <mat-icon class="material-symbols-outlined text-body">group</mat-icon>
            </button>
            <!-- help -->
            <a [href]="helpUrl"
               target="_blank">
                <button class="bg-white"
                        mat-mini-fab
                        [matTooltip]="'This will take you to the vendor portal'">
                    <mat-icon class="material-symbols-outlined text-body text-body text-body">help</mat-icon>
                </button>
            </a>

            <button class="bg-white"
                    mat-mini-fab
                    [matTooltip]="'Calendar'"
                    (click)="onOpenCalendarDialog()">
                <mat-icon class="material-symbols-outlined text-body">calendar_month</mat-icon>
            </button>

            <div class="d-flex flex-column align-items-center px-2">
                <div class="asda-fs-14 text-black-50">Asda Week:</div>
                <div class="asda-fs-16 fw-500 cursor-pointer"
                     [matTooltip]="copyPlaceholder"
                     [matTooltipShowDelay]="0">{{asdaWeek}}</div>
            </div>

            <ng-container *ngIf="profile">
                <button mat-flat-button
                        class="bg-white mat-elevation-z3"
                        [matMenuTriggerFor]="menu">
                    {{ profile.displayName }}
                    <mat-icon iconPositionEnd
                              class="material-symbols-outlined text-body">
                        expand_more
                    </mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="profileService.currentUser?.isPowerUser"
                            mat-menu-item
                            (click)="onOpenDirectSqlDialog()">
                        <mat-icon class="material-symbols-outlined text-body">
                            database
                        </mat-icon>
                        Run direct SQL Query
                    </button>
                    <button *ngIf="profileService.currentUser?.isPowerUser"
                            mat-menu-item
                            (click)="onOpenUploadConfigDialog()">
                        <mat-icon class="material-symbols-outlined text-body">
                            upload
                        </mat-icon>
                        Upload config files
                    </button>
                    <button *ngIf="profileService.currentUser?.isPowerUser"
                            mat-menu-item
                            [routerLink]="'/metadata-dashboard'">
                        <mat-icon class="material-symbols-outlined text-body">
                            dashboard
                        </mat-icon>
                        Metadata dashboard
                    </button>

                    <button *ngIf="profileService.currentUser?.isPowerUser"
                            mat-menu-item
                            [routerLink]="'/status-dashboard'">
                        <mat-icon class="material-symbols-outlined text-body">
                            team_dashboard
                        </mat-icon>
                        Status dashboard
                    </button>

                    <button *ngIf="profileService.currentUser?.isPowerUser && configService.appConfig.apimEndpoint"
                            mat-menu-item>
                        <mat-icon class="material-symbols-outlined text-body">
                            http
                        </mat-icon>
                        <div class="d-flex">
                            ApimEndpoint
                            <mat-icon class="material-symbols-outlined text-body"
                                      [matTooltip]="'Currently using ' + configService.apiEndpoint">
                                info
                            </mat-icon>
                            <mat-slide-toggle (click)="onSwitchEndpoint();$event.stopPropagation()"
                                              color="primary"
                                              [checked]="configService.apiEndpoint === configService.appConfig.apimEndpoint">
                            </mat-slide-toggle>
                        </div>
                    </button>
                    <button *ngIf="profileService.currentUser?.email"
                            mat-menu-item>
                        <mat-icon class="material-symbols-outlined text-body">
                            person
                        </mat-icon>
                        {{ profileService.currentUser?.email }}
                    </button>
                    <button mat-menu-item
                            (click)="onLogout()">
                        <mat-icon class="material-symbols-outlined text-body">
                            logout
                        </mat-icon>
                        Logout
                    </button>
                </mat-menu>
            </ng-container>
        </div>
    </div>
</div>