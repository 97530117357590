import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, tap, throwError } from 'rxjs'

import { IProfile, IUser } from '../models'

import { ApiService } from './api.service'


@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    currentUser?: IUser
    profile: IProfile
    accessDenied = false

    constructor(private httpClient: HttpClient, private apiService: ApiService) {
    }

    fetchCurrentUser(): Observable<IUser> {
        return this.apiService.fetchMe()
            .pipe(
                tap((user) => {
                    this.accessDenied = false
                    this.currentUser = user
                }),
                catchError((error) => {
                    this.currentUser = undefined
                    if ((error as HttpErrorResponse).status === 401) {
                        this.accessDenied = true
                    }
                    return throwError(() => error)
                }),
            )
    }

    fetchProfile(): Observable<IProfile> {
        return this.httpClient.get<IProfile>('https://graph.microsoft.com/v1.0/me')
            .pipe(
                tap(profile => this.profile = profile),
            )
    }

}
