import { Injectable, OnDestroy } from '@angular/core'
import { Observable, Subject, Subscription, catchError, exhaustMap, filter, of, tap, throwError, timer } from 'rxjs'

import { ILastReportRunResponse } from '../models'

import { ApiService } from './api.service'


@Injectable({
    providedIn: 'root',
})
export class LastReportRunsService implements OnDestroy {

    private subscription: Subscription

    readonly lastReportRun$ = new Subject<ILastReportRunResponse>()

    constructor(private apiService: ApiService) { }

    startIntervalPooling(intervalInMs: number) {
        this.stopIntervalPooling()
        this.subscription = timer(0, intervalInMs)
            .pipe(
                exhaustMap(() => this.loadLastCompletedRun()),
                catchError((error) => {
                    return throwError(() => error)
                }),
                filter((response) => response !== null),
                tap((response) => this.lastReportRun$.next(response!)),

            )
            .subscribe()
    }

    stopIntervalPooling() {
        this.subscription?.unsubscribe()
    }

    ngOnDestroy(): void {
        this.stopIntervalPooling()
    }

    protected loadLastCompletedRun(): Observable<ILastReportRunResponse | null> {
        return this.apiService.fetchLastReportRuns()
            .pipe(
                catchError(() => {
                    return of(null)
                }),
            )
    }

}
