import { HttpErrorResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { ProfileService } from '@shared/services'
import { catchError, map, of } from 'rxjs'


export namespace CurrentUserGuard {
    export const canActivate: CanActivateFn = () => {
        const profileService = inject(ProfileService)
        const router = inject(Router)

        if (profileService.currentUser) {
            return of(true)
        }

        return profileService.fetchCurrentUser()
            .pipe(
                catchError((error) => {
                    if ((error as HttpErrorResponse).status === 401) {
                        void router.navigate(['/error/access-denied'])
                        return of(false)
                    }
                    else {
                        void router.navigate(['/error'])
                        return of(false)
                    }
                }),
                map((result) => !!result),
            )
    }
}
