import { HttpBackend, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, tap, throwError } from 'rxjs'

import { IAppConfig } from '../models'


@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _appConfig: IAppConfig


    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _apiEndpoint: string


    constructor(private httpBackend: HttpBackend) { }

    loadConfig() {
        const configFileUrl = './assets/config.json'
        return new Promise<void>((resolve, reject) => {
            new HttpClient(this.httpBackend).get<IAppConfig>(configFileUrl)
                .pipe(
                    tap((appConfig) => this._appConfig = appConfig),
                    tap((appConfig) => this._apiEndpoint = appConfig.apiEndpoint),
                    catchError((error) => {
                        console.error('Error during loading config.json:', error)
                        reject(error)
                        return throwError(() => error)
                    }),
                )
                .subscribe(() => {
                    resolve()
                })
        })
    }

    setApiEndpoint(apiEndpoint: string): void {
        this._apiEndpoint = apiEndpoint
    }

    get apiEndpoint(): string {
        return this._apiEndpoint
    }

    get appConfig(): IAppConfig {
        if (!this._appConfig) {
            throw Error('Config file not loaded!')
        }

        return this._appConfig
    }

}
