import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { isArray } from 'lodash-es'


export type AbstractControlWarn =
    AbstractControl
    & {
        warnings?: Record<string, string>
    }

export function invalidFormatWarningValidator(pattern?: string): ValidatorFn {

    return (control: AbstractControlWarn): ValidationErrors | null => {

        if (!control.value || !pattern) {
            return null
        }

        if (isArray(control.value)) {
            control.warnings = {}
            control.value.forEach((value: string) => {
                const isInvalidFormat = !RegExp(pattern).test(value)
                if (isInvalidFormat) {
                    control.warnings = { invalidFormat: getInvalidFormatMessage(value) }
                    return
                }
            })
        }
        else {
            const isInvalidFormat = !RegExp(pattern).test(control.value as string)
            control.warnings = isInvalidFormat ? { invalidFormat: getInvalidFormatMessage(control.value as string) } : {}
        }

        return null
    }

}

function getInvalidFormatMessage(value: string): string {
    return `Value '${value}' is not in expected format`
}
