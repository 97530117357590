import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'
import { AppBaseComponent } from '@common/components'
import { IBreadcrumb } from '@shared/models'


@Component({
    selector: 'app-asda-breadcrumb',
    templateUrl: './asda-breadcrumb.component.html',
    styleUrls: ['./asda-breadcrumb.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        RouterModule,
    ],
})
export class AsdaBreadcrumbComponent extends AppBaseComponent {

    @Input() breadcrumbs: IBreadcrumb[] | null = []

}
