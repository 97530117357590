<div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center p-4">
        <h4>Upload config file</h4>
        <button mat-icon-button
                aria-label="close dialog"
                mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-progress-bar *ngIf="isProcessing"
                      mode="indeterminate"></mat-progress-bar>
    <mat-dialog-content class="pt-3 overflow-hidden">
        <form class="d-flex flex-column gap-2"
              [formGroup]="formGroup"
              (drop)="onDrop($event)"
              (dragover)="onDragOver($event)"
              (submit)="onSubmit()">

            <mat-form-field class="w-100"
                            appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select [formControlName]="'type'">
                    <ng-container *ngFor="let type of UploadMetadataTypeLabel | keyvalue">
                        <mat-option [value]="type.key">{{ type.value }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <!-- FILE UPLOAD INPUT -->
            <mat-form-field class="w-100"
                            appearance="outline">
                <mat-label>File</mat-label>

                <input matInput
                       class="cursor-pointer"
                       type="text"
                       [readonly]="true"
                       [formControlName]="'fileName'"
                       (click)="inputElm.click()">
                <mat-icon *ngIf="!uploadedFile"
                          class="cursor-pointer"
                          matSuffix
                          (click)="inputElm.click()">attach_file</mat-icon>
                <mat-icon *ngIf="!!uploadedFile"
                          class="cursor-pointer"
                          matSuffix
                          (click)="inputElm.value = null!; onRemoveFile()">clear</mat-icon>
            </mat-form-field>

            <input #inputElm
                   class="invisible"
                   [accept]="acceptedFileTypes"
                   id="fileInput"
                   type="file"
                   (change)="onFileChange($event)">

            <button mat-flat-button
                    class="mt-3 text-white"
                    type="button"
                    [disabled]="!formGroup.valid || isProcessing"
                    color="primary"
                    (click)="onSubmit()">
                {{isProcessing ? 'Processing...' : 'Upload' }}
            </button>
        </form>
    </mat-dialog-content>
</div>