import { Component, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'


@Component({
    selector: 'app-base',
    template: '',
})
export class AppBaseComponent implements OnDestroy {

    readonly destroyed$ = new Subject<void>()

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

}

