import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AppBaseComponent } from '@common/components'
import { Subject, debounceTime, takeUntil } from 'rxjs'


@Component({
    selector: 'app-asda-search',
    templateUrl: './asda-search.component.html',
    styleUrls: ['./asda-search.component.scss'],
})
export class AsdaSearchComponent extends AppBaseComponent {

    @Input() placeholder!: string
    @Output() searchChange$ = new EventEmitter<string>()

    searchTerm = ''

    private searchTermsSubject = new Subject<string>()

    constructor() {
        super()
        this.searchTermsSubject.pipe(debounceTime(700))
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((term) => {
                this.searchChange$.emit(term)
            })
    }

    onSearchTermChange(): void {
        this.searchTermsSubject.next(this.searchTerm)
    }

}
