import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MsalRedirectComponent } from '@azure/msal-angular'
import { AsdaBreadcrumbComponent, NavbarComponent, NoticeComponent } from '@shared/components'
import { APP_DATE_FORMATS } from '@shared/constants'
import { HeaderApiKeyInterceptor, HeaderApimKeyInterceptor } from '@shared/interceptors'
import { MsalApplicationModule } from '@shared/modules'
import { ConfigService } from '@shared/services'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'


export function initializeApp(configService: ConfigService) {
    return () => configService.loadConfig()
}
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        MatNativeDateModule,
        NavbarComponent,
        NoticeComponent,
        AsdaBreadcrumbComponent,
        MsalApplicationModule.forRoot(),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB',
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: APP_DATE_FORMATS,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderApiKeyInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderApimKeyInterceptor,
            multi: true,
        },
        provideCharts(withDefaultRegisterables()),
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent,
    ],
})
export class AppModule { }
