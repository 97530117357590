import { sortBy } from 'lodash-es'

import { ITemplateStage, TemplateStageType } from './api.models'


export type IExtendedTemplateStage = ITemplateStage & {
    order: number
}

const stageOrder: TemplateStageType[] = [
    TemplateStageType.COLUMNS,
    TemplateStageType.ITEMS,
    TemplateStageType.LOCATIONS,
    TemplateStageType.EVENTS,
    TemplateStageType.OPTIONS,
    TemplateStageType.REMITTANCES,
    TemplateStageType.ADJUSTMENTS,
    TemplateStageType.TIMES,
    TemplateStageType.DEPARTMENTS,
    TemplateStageType.SALES_TYPES,
    TemplateStageType.POS,
    TemplateStageType.CUSTOMERS,
    TemplateStageType.FILTERS,
    TemplateStageType.REPORT_FILTERS,
]

export const stagesWithOperators: TemplateStageType[] = [
    TemplateStageType.ITEMS,
    TemplateStageType.LOCATIONS,
    TemplateStageType.EVENTS,
    TemplateStageType.OPTIONS,
    TemplateStageType.REMITTANCES,
    TemplateStageType.ADJUSTMENTS,
    TemplateStageType.TIMES,
    TemplateStageType.DEPARTMENTS,
    TemplateStageType.SALES_TYPES,
    TemplateStageType.POS,
    TemplateStageType.CUSTOMERS,
    TemplateStageType.FILTERS,
    TemplateStageType.REPORT_FILTERS,
]

export function getOrderedTemplateStages(stages: ITemplateStage[]): IExtendedTemplateStage[] {
    return sortBy(stages.map((stage) => ({
        ...stage,
        order: stageOrder.indexOf(stage.stageType),
    })), stage => stage.order)
}
