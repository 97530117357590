import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { IBreadcrumb } from '@shared/models'
import { BehaviorSubject } from 'rxjs'


@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    private breadcrumbsSubject = new BehaviorSubject<IBreadcrumb[]>([])
    breadcrumbs$ = this.breadcrumbsSubject.asObservable()
    locked = false

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && !this.locked) {
                this.setBreadcrumbs([])
            }
        })
    }

    setBreadcrumbs(breadcrumbs: IBreadcrumb[], locked = false) {
        this.breadcrumbsSubject.next(breadcrumbs)
        this.locked = locked
    }

    unlock() {
        this.locked = false
    }

}
