import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { SafeHtmlPipe } from '@shared/pipes'


@Component({
    selector: 'app-notice-board',
    templateUrl: './notice-board.component.html',
    styleUrls: ['./notice-board.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatCardModule,
        SafeHtmlPipe,
    ],
})

export class NoticeBoardComponent {

    @Input() noticeBoardContent: string
    @Input() noticeBoardFooter: string

}
