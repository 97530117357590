import { Pipe, PipeTransform } from '@angular/core'
import { BYTES_TO_KB, BYTES_TO_MB } from '@shared/constants'


@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {

    transform(value: number): string {
        if (value <= 0) {
            return '0 kB'
        }

        if (value < 500) {
            return `${value} B`
        }
        else if (value < 500000) {
            const kbValue = (value * BYTES_TO_KB).toFixed(2)
            return `${kbValue} kB`
        }
        else {
            const mbValue = (value * BYTES_TO_MB).toFixed(2)
            return `${mbValue} MB`
        }
    }

}
