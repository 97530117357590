export interface IProfile {
    id: string
    userPrincipalName: string
    businessPhones: Array<string>
    displayName: string
    givenName: string
    jobTitle: string
    mail: string
    mobilePhone: string
    officeLocation: string
    preferredLanguage: string
    surname: string
}
