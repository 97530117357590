<mat-card [appearance]="'outlined'"
          class="notice-board__card">

    <div class="notice-board__header">
        <h3>ADR Updates & News</h3>
    </div>

    <div *ngIf="noticeBoardContent"
         class="notice-board__content"
         [innerHTML]="noticeBoardContent | safeHtml">
    </div>

    <div *ngIf="noticeBoardFooter"
         class="notice-board__footer"
         [innerHTML]="noticeBoardFooter | safeHtml">
    </div>
</mat-card>