import { Component, Input } from '@angular/core'


@Component({
    selector: 'app-asda-icon',
    templateUrl: './asda-icon.component.html',
    styleUrls: ['./asda-icon.component.scss'],
})
export class AsdaIconComponent {

    @Input() iconName!: string

}
