import { Component, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'


@Component({
    selector: 'app-snackbar-component',
    templateUrl: './asda-snack-bar.component.html',
})
export class AsdaSnackBarComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { icon: string; message: string }) { }

}
